/* -------------------------------------------------------------------------

	PUNITED.common

	サイト内で共通のスクリプトを定義
	※jQuery必須
	Potential United inc.

------------------------------------------------------------------------- */

// グローバル名前空間定義
var PUNITED;
if (!PUNITED) PUNITED = {};
if (!PUNITED.common) PUNITED.common = {};

PUNITED.common.ua = (function(u){
	return {
		Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1)
		|| u.indexOf("ipad") != -1
		|| (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
		|| (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
		|| u.indexOf("kindle") != -1
		|| u.indexOf("silk") != -1
		|| u.indexOf("playbook") != -1,
		Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
		|| u.indexOf("iphone") != -1
		|| u.indexOf("ipod") != -1
		|| (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
		|| (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
		|| u.indexOf("blackberry") != -1
	}
})(window.navigator.userAgent.toLowerCase());


/* -----------------------------------------------------
レスポンシブ処理
----------------------------------------------------- */
PUNITED.common.responsiveFunc = function() {

	var W = window.screen.width;

	if( W > 640 )
		return;

	var viewport = document.getElementsByName("viewport");
	if( viewport.length > 0 ) {
		for(var i=0; i <= viewport.length; i++){
			viewport[i].parentNode.removeChild(viewport[i]);
		}
	}

	// viewportセット
	var viewport = document.createElement("meta");
	viewport.setAttribute("name", "viewport");
	viewport.setAttribute("content", "width=device-width,initial-scale=1");

	document.getElementsByTagName("head")[0].appendChild(viewport);

}
PUNITED.common.responsiveFunc();

PUNITED.common.ww = window.innerWidth;
PUNITED.common.wh = window.innerHeight;
$(window).on('resize', function(){
	PUNITED.common.ww = window.innerWidth;
	PUNITED.common.wh = window.innerHeight;
});

(function (){
	// グローバル名前空間オブジェクトをローカルオブジェクトに置き換え
	var ns = PUNITED.common;


	/* -----------------------------------------------------
		IE10以下の場合にメッセージを表示
	----------------------------------------------------- */
	if ($.browser.is('msie') && $.browser.version.isOrLess(10)) {
		$("html").addClass("regacy_ie");
	}

	/* -----------------------------------------------------
	絶対パス取得
	----------------------------------------------------- */
	ns.getAbsPath = function(path) {
		var e = document.createElement('span');
		e.innerHTML = '<a href="'+path+'" />';
		return e.firstChild.href;
	}

	/* -----------------------------------------------------
	UIKIT OffCanvas
	----------------------------------------------------- */
	ns.offcanvas = function() {

		! function (t) {
			if ("function" == typeof define && define.amd && define("uikit", function () {
					var n = t(window, window.jQuery, window.document);
					return n.load = function (t, e, o, i) {
						var r, a = t.split(","),
							s = [],
							c = (i.config && i.config.uikit && i.config.uikit.base ? i.config.uikit.base : "").replace(/\/+$/g, "");
						if (!c) throw new Error("Please define base path to UIkit in the requirejs config.");
						for (r = 0; r < a.length; r += 1) {
							var u = a[r].replace(/\./g, "/");
							s.push(c + "/components/" + u)
						}
						e(s, function () {
							o(n)
						})
					}, n
				}), !window.jQuery) throw new Error("UIkit requires jQuery");
			window && window.jQuery && t(window, window.jQuery, window.document)
		}(function (t, n, e) {
			"use strict";
			var o = {},
				i = window.UIkit;
			if (o.version = "2.13.1", o._prefix = "uk", o.noConflict = function (t) {
					return i && (window.UIkit = i, n.UIkit = i, n.fn.uk = i.fn), o._prefix = t, o
				}, o.prefix = function (t) {
					return "string" == typeof t ? t.replace(/@/g, o._prefix) : t
				}, o.$ = function () {
					arguments[0] && "string" == typeof arguments[0] && (arguments[0] = o.prefix(arguments[0]));
					var t, e = n.apply(n, arguments);
					return e.length ? (["find", "filter", "closest", "attr", "parent", "parents", "children", "addClass", "removeClass", "toggleClass", "hasClass", "is", "on", "one"].forEach(function (n) {
						var i, r = e[n],
							a = ["find", "filter", "parent", "parents", "children", "closest"];
						return e[n] = function () {
							for (t = 0; t < arguments.length; t++) "string" == typeof arguments[t] && (arguments[t] = o.prefix(arguments[t]));
							return i = r.apply(this, arguments), a.indexOf(n) > -1 ? o.$(i) : i
						}, e
					}), e) : e
				}, o.$doc = o.$(document), o.$win = o.$(window), o.$html = o.$("html"), o.fn = function (t, e) {
					var i = arguments,
						r = t.match(/^([a-z\-]+)(?:\.([a-z]+))?/i),
						a = r[1],
						s = r[2];
					return o[a] ? this.each(function () {
						var t = n(this),
							r = t.data(a);
						r || t.data(a, r = o[a](this, s ? void 0 : e)), s && r[s].apply(r, Array.prototype.slice.call(i, 1))
					}) : (n.error("UIkit component [" + a + "] does not exist."), this)
				}, o.support = {}, o.support.transition = function () {
					var t = function () {
						var t, n = e.body || e.documentElement,
							o = {
								WebkitTransition: "webkitTransitionEnd",
								MozTransition: "transitionend",
								OTransition: "oTransitionEnd otransitionend",
								transition: "transitionend"
							};
						for (t in o)
							if (void 0 !== n.style[t]) return o[t]
					}();
					return t && {
						end: t
					}
				}(), o.support.animation = function () {
					var t = function () {
						var t, n = e.body || e.documentElement,
							o = {
								WebkitAnimation: "webkitAnimationEnd",
								MozAnimation: "animationend",
								OAnimation: "oAnimationEnd oanimationend",
								animation: "animationend"
							};
						for (t in o)
							if (void 0 !== n.style[t]) return o[t]
					}();
					return t && {
						end: t
					}
				}(), o.support.requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame || function (t) {
					setTimeout(t, 1e3 / 60)
				}, o.support.touch = "ontouchstart" in window && navigator.userAgent.toLowerCase().match(/mobile|tablet/) || t.DocumentTouch && document instanceof t.DocumentTouch || t.navigator.msPointerEnabled && t.navigator.msMaxTouchPoints > 0 || t.navigator.pointerEnabled && t.navigator.maxTouchPoints > 0 || !1, o.support.mutationobserver = t.MutationObserver || t.WebKitMutationObserver || null, o.Utils = {}, o.Utils.str2json = function (t) {
					return t.replace(/([\$\w]+)\s*:/g, function (t, n) {
						return '"' + n + '":'
					}).replace(/'([^']+)'/g, function (t, n) {
						return '"' + n + '"'
					})
				}, o.Utils.debounce = function (t, n, e) {
					var o;
					return function () {
						var i = this,
							r = arguments,
							a = function () {
								o = null, e || t.apply(i, r)
							},
							s = e && !o;
						clearTimeout(o), o = setTimeout(a, n), s && t.apply(i, r)
					}
				}, o.Utils.removeCssRules = function (t) {
					var n, e, o, i, r, a, s, c, u, f;
					t && setTimeout(function () {
						try {
							for (f = document.styleSheets, i = 0, s = f.length; s > i; i++) {
								for (o = f[i], e = [], o.cssRules = o.cssRules, n = r = 0, c = o.cssRules.length; c > r; n = ++r) o.cssRules[n].type === CSSRule.STYLE_RULE && t.test(o.cssRules[n].selectorText) && e.unshift(n);
								for (a = 0, u = e.length; u > a; a++) o.deleteRule(e[a])
							}
						} catch (d) {}
					}, 0)
				}, o.Utils.isInView = function (t, e) {
					var i = n(t);
					if (!i.is(":visible")) return !1;
					var r = o.$win.scrollLeft(),
						a = o.$win.scrollTop(),
						s = i.offset(),
						c = s.left,
						u = s.top;
					return e = n.extend({
						topoffset: 0,
						leftoffset: 0
					}, e), u + i.height() >= a && u - e.topoffset <= a + o.$win.height() && c + i.width() >= r && c - e.leftoffset <= r + o.$win.width() ? !0 : !1
				}, o.Utils.checkDisplay = function (t, e) {
					var i = o.$("[data-@-margin], [data-@-grid-match], [data-@-grid-margin], [data-@-check-display]", t || document);
					return t && !i.length && (i = n(t)), i.trigger("display.uk.check"), e && ("string" != typeof e && (e = o.prefix('[class*="@-animation-"]')), i.find(e).each(function () {
						var t = o.$(this),
							n = t.attr("class"),
							e = n.match(/uk\-animation\-(.+)/);
						t.removeClass(e[0]).width(), t.addClass(e[0])
					})), i
				}, o.Utils.options = function (t) {
					if (n.isPlainObject(t)) return t;
					var e = t ? t.indexOf("{") : -1,
						i = {};
					if (-1 != e) try {
						i = JSON.parse(o.Utils.str2json(t.substr(e)))
					} catch (r) {}
					return i
				}, o.Utils.animate = function (t, e) {
					var i = n.Deferred();
					return t = o.$(t), e = o.prefix(e), t.css("display", "none").addClass(e).one(o.support.animation.end, function () {
						t.removeClass(e), i.resolve()
					}).width(), t.css("display", ""), i.promise()
				}, o.Utils.uid = function (t) {
					return (t || "id") + (new Date).getTime() + "RAND" + Math.ceil(1e5 * Math.random())
				}, o.Utils.template = function (t, n) {
					for (var e, o, i, r, a = t.replace(/\n/g, "\\n").replace(/\{\{\{\s*(.+?)\s*\}\}\}/g, "{{!$1}}").split(/(\{\{\s*(.+?)\s*\}\})/g), s = 0, c = [], u = 0; s < a.length;) {
						if (e = a[s], e.match(/\{\{\s*(.+?)\s*\}\}/)) switch (s += 1, e = a[s], o = e[0], i = e.substring(e.match(/^(\^|\#|\!|\~|\:)/) ? 1 : 0), o) {
						case "~":
							c.push("for(var $i=0;$i<" + i + ".length;$i++) { var $item = " + i + "[$i];"), u++;
							break;
						case ":":
							c.push("for(var $key in " + i + ") { var $val = " + i + "[$key];"), u++;
							break;
						case "#":
							c.push("if(" + i + ") {"), u++;
							break;
						case "^":
							c.push("if(!" + i + ") {"), u++;
							break;
						case "/":
							c.push("}"), u--;
							break;
						case "!":
							c.push("__ret.push(" + i + ");");
							break;
						default:
							c.push("__ret.push(escape(" + i + "));")
						} else c.push("__ret.push('" + e.replace(/\'/g, "\\'") + "');");
						s += 1
					}
					return r = new Function("$data", ["var __ret = [];", "try {", "with($data){", u ? '__ret = ["Not all blocks are closed correctly."]' : c.join(""), "};", "}catch(e){__ret = [e.message];}", 'return __ret.join("").replace(/\\n\\n/g, "\\n");', "function escape(html) { return String(html).replace(/&/g, '&amp;').replace(/\"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');}"].join("\n")), n ? r(n) : r
				}, o.Utils.events = {}, o.Utils.events.click = o.support.touch ? "tap" : "click", window.UIkit = o, n.UIkit = o, n.fn.uk = o.fn, o.langdirection = "rtl" == o.$html.attr("dir") ? "right" : "left", o.components = {}, o.component = function (t, e) {
					var i = function (e, r) {
						var a = this;
						return this.UIkit = o, this.element = e ? o.$(e) : null, this.options = n.extend(!0, {}, this.defaults, r), this.plugins = {}, this.element && this.element.data(t, this), this.init(), (this.options.plugins.length ? this.options.plugins : Object.keys(i.plugins)).forEach(function (t) {
							i.plugins[t].init && (i.plugins[t].init(a), a.plugins[t] = !0)
						}), this.trigger("init.uk.component", [t, this]), this
					};
					return i.plugins = {}, n.extend(!0, i.prototype, {
						defaults: {
							plugins: []
						},
						boot: function () {},
						init: function () {},
						on: function (t, n, e) {
							return o.$(this.element || this).on(t, n, e)
						},
						one: function (t, n, e) {
							return o.$(this.element || this).one(t, n, e)
						},
						off: function (t) {
							return o.$(this.element || this).off(t)
						},
						trigger: function (t, n) {
							return o.$(this.element || this).trigger(t, n)
						},
						find: function (t) {
							return o.$(this.element ? this.element : []).find(t)
						},
						proxy: function (t, n) {
							var e = this;
							n.split(" ").forEach(function (n) {
								e[n] || (e[n] = function () {
									return t[n].apply(t, arguments)
								})
							})
						},
						mixin: function (t, n) {
							var e = this;
							n.split(" ").forEach(function (n) {
								e[n] || (e[n] = t[n].bind(e))
							})
						}
					}, e), this.components[t] = i, this[t] = function () {
						var e, i;
						if (arguments.length) switch (arguments.length) {
						case 1:
							"string" == typeof arguments[0] || arguments[0].nodeType || arguments[0] instanceof jQuery ? e = n(arguments[0]) : i = arguments[0];
							break;
						case 2:
							e = n(arguments[0]), i = arguments[1]
						}
						return e && e.data(t) ? e.data(t) : new o.components[t](e, i)
					}, o.domready && o.component.boot(t), i
				}, o.plugin = function (t, n, e) {
					this.components[t].plugins[n] = e
				}, o.component.boot = function (t) {
					o.components[t].prototype && o.components[t].prototype.boot && !o.components[t].booted && (o.components[t].prototype.boot.apply(o, []), o.components[t].booted = !0)
				}, o.component.bootComponents = function () {
					for (var t in o.components) o.component.boot(t)
				}, o.domObservers = [], o.domready = !1, o.ready = function (t) {
					o.domObservers.push(t), o.domready && t(document)
				}, o.on = function (t, n, e) {
					return t && t.indexOf("ready.uk.dom") > -1 && o.domready && n.apply(o.$doc), o.$doc.on(t, n, e)
				}, o.one = function (t, n, e) {
					return t && t.indexOf("ready.uk.dom") > -1 && o.domready ? (n.apply(o.$doc), o.$doc) : o.$doc.one(t, n, e)
				}, o.trigger = function (t, n) {
					return o.$doc.trigger(t, n)
				}, o.domObserve = function (t, n) {
					o.support.mutationobserver && (n = n || function () {}, o.$(t).each(function () {
						var t = this,
							e = o.$(t);
						if (!e.data("observer")) try {
							var i = new o.support.mutationobserver(o.Utils.debounce(function () {
								n.apply(t, []), e.trigger("changed.uk.dom")
							}, 50));
							i.observe(t, {
								childList: !0,
								subtree: !0
							}), e.data("observer", i)
						} catch (r) {}
					}))
				}, n(function () {
					o.$body = o.$("body"), o.ready(function (t) {
						o.domObserve("[data-@-observe]", t || document)
					}), o.on("ready.uk.dom", function () {
						o.domObservers.forEach(function (t) {
							t(document)
						}), o.domready && o.Utils.checkDisplay(document)
					}), o.on("changed.uk.dom", function (t) {
						var n = t.target;
						o.domObservers.forEach(function (t) {
							t(n)
						}), o.Utils.checkDisplay(n)
					}), o.trigger("beforeready.uk.dom"), o.component.bootComponents(), setInterval(function () {
						var t, n = {
								x: window.pageXOffset,
								y: window.pageYOffset
							},
							e = function () {
								(n.x != window.pageXOffset || n.y != window.pageYOffset) && (t = {
									x: 0,
									y: 0
								}, window.pageXOffset != n.x && (t.x = window.pageXOffset > n.x ? 1 : -1), window.pageYOffset != n.y && (t.y = window.pageYOffset > n.y ? 1 : -1), n = {
									dir: t,
									x: window.pageXOffset,
									y: window.pageYOffset
								}, o.$doc.trigger("scrolling.uk.document", [n]))
							};
						return o.support.touch && o.$html.on("touchmove touchend MSPointerMove MSPointerUp pointermove pointerup", e), (n.x || n.y) && e(), e
					}(), 15), o.trigger("ready.uk.dom"), o.support.touch && navigator.userAgent.match(/(iPad|iPhone|iPod)/g) && o.$win.on("load orientationchange resize", o.Utils.debounce(function () {
						var t = function () {
							return n(o.prefix(".@-height-viewport")).css("height", window.innerHeight), t
						};
						return t()
					}(), 100)), o.trigger("afterready.uk.dom"), o.domready = !0
				}), o.$html.addClass(o.support.touch ? "@-touch" : "@-notouch"), o.support.touch) {
				var r, a = !1,
					s = ".@-overlay, .@-overlay-toggle, .@-caption-toggle, .@-animation-hover, .@-has-hover";
				o.$html.on("touchstart MSPointerDown pointerdown", s, function () {
					a && o.$(".@-hover").removeClass("@-hover"), a = o.$(this).addClass("@-hover")
				}).on("touchend MSPointerUp pointerup", function (t) {
					r = o.$(t.target).parents(s), a && a.not(r).removeClass("@-hover")
				})
			}
			return o
		}),
		function (t, n) {
			"use strict";
			var e = {
					x: window.scrollX,
					y: window.scrollY
				},
				o = (n.$win, n.$doc),
				i = n.$html,
				r = {
					show: function (t) {
						if (t = n.$(t), t.length) {
							var r = n.$("body"),
								a = t.find(".@-offcanvas-bar:first"),
								s = "right" == n.langdirection,
								c = a.hasClass("@-offcanvas-bar-flip") ? -1 : 1,
								u = c * (s ? -1 : 1);
							e = {
								x: window.pageXOffset,
								y: window.pageYOffset
							}, t.addClass("@-active"), r.css({
								width: window.innerWidth,
								height: window.innerHeight
							}).addClass("@-offcanvas-page"), r.css(s ? "margin-right" : "margin-left", (s ? -1 : 1) * a.outerWidth() * u).width(), i.css("margin-top", -1 * e.y), a.addClass("@-offcanvas-bar-show"), this._initElement(t), o.trigger("show.uk.offcanvas", [t, a])
						}
					},
					hide: function (t) {
						var o = n.$("body"),
							r = n.$(".@-offcanvas.@-active"),
							a = "right" == n.langdirection,
							s = r.find(".@-offcanvas-bar:first"),
							c = function () {
								o.removeClass("@-offcanvas-page").css({
									width: "",
									height: "",
									"margin-left": "",
									"margin-right": ""
								}), r.removeClass("@-active"), s.removeClass("@-offcanvas-bar-show"), i.css("margin-top", ""), window.scrollTo(e.x, e.y), n.$doc.trigger("hide.uk.offcanvas", [r, s])
							};
						r.length && (n.support.transition && !t ? (o.one(n.support.transition.end, function () {
							c()
						}).css(a ? "margin-right" : "margin-left", ""), setTimeout(function () {
							s.removeClass("@-offcanvas-bar-show")
						}, 0)) : c())
					},
					_initElement: function (e) {
						e.data("OffcanvasInit") || (e.on("click.uk.offcanvas swipeRight.uk.offcanvas swipeLeft.uk.offcanvas", function (t) {
							var e = n.$(t.target);
							if (!t.type.match(/swipe/) && !e.hasClass("@-offcanvas-close")) {
								if (e.hasClass("@-offcanvas-bar")) return;
								if (e.parents(".@-offcanvas-bar:first").length) return
							}
							t.stopImmediatePropagation(), r.hide()
						}), e.on("click", "a[href^='#']", function () {
							var e = t(this),
								o = e.attr("href");
							"#" != o && (n.$doc.one("hide.uk.offcanvas", function () {
								var e = t(o);
								e.length || (e = n.$('[name="' + o.replace("#", "") + '"]')), n.Utils.scrollToElement && e.length ? n.Utils.scrollToElement(e) : window.location.href = o
							}), r.hide())
						}), e.data("OffcanvasInit", !0))
					}
				};
			n.component("offcanvasTrigger", {
				boot: function () {
					i.on("click.offcanvas.uikit", "[data-@-offcanvas]", function (t) {
						t.preventDefault();
						var e = n.$(this);
						if (!e.data("offcanvasTrigger")) {
							{
								n.offcanvasTrigger(e, n.Utils.options(e.attr("data-@-offcanvas")))
							}
							e.trigger("click")
						}
					}), i.on("keydown.uk.offcanvas", function (t) {
						27 === t.keyCode && r.hide()
					})
				},
				init: function () {
					var n = this;
					this.options = t.extend({
						target: n.element.is("a") ? n.element.attr("href") : !1
					}, this.options), this.on("click", function (t) {
						t.preventDefault(), r.show(n.options.target)
					})
				}
			}), n.offcanvas = r
		}(jQuery, UIkit);

	}

	/* -----------------------------------------------------
	UIKIT OffCanvas
	----------------------------------------------------- */
	ns.offcanvas_extend = function() {

		$(document).on('click', '#drawerSitemap a', function (e) {
			var _href = $(this).attr('href');
			if( _href.indexOf('#') != -1 ){
				var abs_path = ns.getAbsPath( _href );
				if( abs_path.replace(/#.*$/,"") == location.href.replace(/#.*$/,"") ){

					UIkit.offcanvas.hide([force = false]);

					// ページ内リンク
					var _selector = _href.substring(_href.indexOf("#")+1, _href.length);
					$target = $('#' + _selector );

					var offset = 40;
					if( parseInt($('.sidebar:first').css('height')) < window.innerHeight )
						offset += $('.sidebar__inner:first').outerHeight(true);

					var top = $target.offset().top - offset;

					$("html,body").stop().animate({scrollTop: top});

				}
			}
		});

		$('.drawer_btn').on('click touchstart', '.close', function (e) {
			UIkit.offcanvas.hide();
		});

		UIkit.$doc.on({
			'show.uk.offcanvas': function(){
			},
			'hide.uk.offcanvas': function(){
			}
		});

	}

	/* -----------------------------------------------------
	header scroll
	----------------------------------------------------- */
	ns.header_scroll = function() {
		var $target = $('body.page .site_header');
		$(window).on('scroll', function(){
			if( PUNITED.common.ww <= 640 ){
				$target.css('left', '');
				return;
			}
			$target.css('left', -1 * $(window).scrollLeft());
		});
	}

	/* -----------------------------------------------------
	global nav
	----------------------------------------------------- */
	ns.global_nav = function() {
		$('body.page .drawer_nav .uk-offcanvas-bar .lv1 > li').each(function(){
			var $child = $(this).find('.lv2').hide();
			$(this).on({
				mouseenter: function(){
					if( PUNITED.common.ww <= 640 )
						return;
					if( $child.queue() < 1 )
						$child.fadeIn(300);
				},
				mouseleave: function(){
					if( PUNITED.common.ww <= 640 )
						return;
					$child.stop().fadeOut(300);
				}
			});
		});
	}

	/* -----------------------------------------------------
	check .page_main_image__bg aspect ratio
	----------------------------------------------------- */
	ns.check_page_main_bg = function() {
		var
			$target = $('.page_main_image__bg'),
			$wrapper = $target.closest('.page_main_image'),
			org = new Image()
		;
		org.src = $target.attr('src');
		var
			org_ratio = org.width / org.height,
			dom_ratio = $wrapper.width() / $wrapper.height()
		;
		var timer = null;
		function main(){
			if( dom_ratio > org_ratio ){
				$target.addClass('ratio--wide');
			} else {
				$target.removeClass('ratio--wide');
			}
		}
		main();
		$(window).on('resize',function() {
			clearTimeout(timer);
			timer = setTimeout(function() {
				dom_ratio = $wrapper.width() / $wrapper.height();
				main();
			}, 10);
		});
	}

	/* -----------------------------------------------------
	.page_main_image__description responsive
	----------------------------------------------------- */
	ns.page_main_description = function() {
		var $target = $('.page_main_image__description');
		//$target.closest('.page_main_image').next('.cta_btn').after($target.clone());
		$('main').before($target.clone());
	}

	/* -----------------------------------------------------
	facebook page plugin
	----------------------------------------------------- */
	ns.facebook_page_plugin = function() {
		var windowWidth = $(window).width();
		var htmlStr = $('#pageplugin').html();
		var timer = null;
		$(window).on('resize',function() {
			var resizedWidth = $(window).width();
			if(windowWidth != resizedWidth && resizedWidth < 500) {
				clearTimeout(timer);
				timer = setTimeout(function() {
					$('#pageplugin').html(htmlStr);
					//再レンダリング
					window.FB.XFBML.parse();
					var windowWidth = $(window).width();
				}, 500);
			}
		});
	}

	/* -----------------------------------------------------
	メインヘッダースクロールチェック
	----------------------------------------------------- */
	ns.check_main_header_y = function() {
		var
			//base_h = $(".page_main_image,.top_main_image").height(),
			base_h = 200,
			classname = "over_main_image",
			passname = "pass_main_image",
			$target = $("html")
		;
		$target.addClass(classname);
		$target.removeClass(passname);
		var timer = null;
		$(window).on("scroll", function(){
			clearTimeout(timer);
			timer = setTimeout(function() {
				if( $(this).scrollTop() > base_h ){
					if( $target.hasClass(classname) ){
						$target.removeClass(classname);
						$target.addClass(passname);
					}
				}else{
					if( !$target.hasClass(classname) ){
						var
							html_o = Math.abs( parseInt( $('html').css('marginTop') ) )
						;
						if( html_o < base_h ){
							$target.addClass(classname);
							$target.removeClass(passname);
						}
					}
				}
			}, 10);
		}).trigger("scroll");
	}


	/* -----------------------------------------------------
	useful simpleToggle clone
	----------------------------------------------------- */
	ns.drawerToggle = function(set, trigger, block, selectedClass, speed) {
		var $sets = $(set);
		if(!selectedClass) selectedClass = "selected";
		if(!speed) speed = "normal";

		$sets.each(function(index, element) {
			var
				$set = $(element),
				$trigger = $set.find(trigger),
				$block = $set.find(block).hide();

			$trigger.on("click", function(event){
				if( !$('body').hasClass('is_menu_active') )
					return;
				$block.slideToggle(speed);
				$set.toggleClass(selectedClass);
				return false;
			});
		});
	}


	/* -----------------------------------------------------
	page plan family
	----------------------------------------------------- */
	ns.page_style = function() {

		if( $('body').hasClass('plan') && $('body').hasClass('family') ){
			$('.page_section.attr_section_03 .page_section__title').before( $('.page_section.attr_section_03 .page_section__contents').clone().addClass('sp_none') );
		}

		if( $('body').hasClass('plan') && $('body').hasClass('friend') ){
			$('.page_section.attr_section_02 .page_section__title').append($('.page_section.attr_section_02 .page_section__img').clone());
			$('.page_section.attr_section_02 .page_section__contents').after($('.page_section.attr_section_02 .page_section__title').clone());
		}

		if( $('body').hasClass('plan') && $('body').hasClass('angler') ){
			$('.page_section.attr_section_01 .page_section__title').before( $('.page_section.attr_section_01 .page_section__contents').clone().addClass('sp_none') );

			$('.page_section.attr_section_03 .page_section__title').append($('.page_section.attr_section_03 .page_section__img').clone());
			$('.page_section.attr_section_03 .page_section__contents').after($('.page_section.attr_section_03 .page_section__title').clone());
		}

		$('.module_price_table').each(function(){
			var
				$container = $(this),
				$label = $container.find('.module_price_table__head th'),
				$data_row = $container.find('.module_price_table__body .module_price_table__row')
			;
			$data_row.each(function(){
				var $data = $(this).children();
				$label.each(function(i){
					$data.eq(i).attr('data-label', $(this).text());
				});
			});
		});

		if( $('body').hasClass('oneday') ){
			$('.timeline_section__body').each(function(){
				var $container = $('<div class="timeline_section__column_container">\n\
					<div class="timeline_section__left_column"></div>\n\
					<div class="timeline_section__right_column"></div>\n\
				</div>');
				$container.children().append($(this).children().clone());
				$(this).after($container);
			});
		}

		PUNITED.useful.replaceImage("data-spimg",639);

	}


	/* -----------------------------------------------------
	ページ固有のメインスライダー
	----------------------------------------------------- */
	ns.page_main_slider = function() {
		var $target = $('#pageMainSlider');
		var _opts = {
			mode: 'fade',
			auto: true,
			pause: 3000,
			pager:false,
			controls: false,
			minSlides: 1,
			maxSlides: 1,
			slideMargin: 0,
			speed: 1500,
			responsive: true,
			adaptiveHeight: true,
			onSliderLoad: function(){
				$target.addClass('animated');
			}
		}
		$target.imagesLoaded( {
			// options...
			},
			function() {
				var mainSlider = $target.bxSlider(_opts);
				var $obj = PUNITED.useful.checkBreakPont([640]);
				$obj.on("onPointChanged onPointInit", function(event, point){
					if( mainSlider.length > 0 )
						mainSlider.reloadSlider(_opts);
				});
			}
		);
	}


	/* -----------------------------------------------------
	アンカーリンク
	----------------------------------------------------- */
	/* ns.anchor_link = function() {
		function innerPageScroll(id){
			var
				$target = $(id),
				offset = 0
			;
			if($target.length > 0){
				var top = $target.offset().top - offset;
				$("html,body").stop().animate({scrollTop:top});
			}
		};
		$("a[href*=\\#]:not([href^=\\#])").on('click', function(e){
			var
				href = $(this).attr('href'),
				abs_path = ns.getAbsPath( href )
			;
			if( abs_path.replace(/#.*$/,"") == location.href.replace(/#.*$/,"") ){
				// ページ内リンク
				e.preventDefault();
				href = href.split("#");
				innerPageScroll("#" + href[1]);
			} else {
				// 外部ページ
			}
		});
		$("a[href^=\\#]").on('click', function(e){
			e.preventDefault();
			var href = $(this).attr('href');
			innerPageScroll(href);
		});
	} */

	/* -----------------------------------------------------
	useful simpleToggle clone
	----------------------------------------------------- */
	ns.footerToggle = function(set, trigger, block, selectedClass, speed) {
		var
			set = ".site_footer__nav .toggle > li",
			trigger = " > a",
			block = " > .lv2",
			selectedClass = "selected",
			speed = "normal"
		;

		var $sets = $(set);

		$sets.each(function(index, element) {
			var
				$set = $(element),
				$trigger = $set.find(trigger),
				$block = $set.find(block).hide();

			$trigger.on("click", function(event){
				if( PUNITED.common.ww > 640 )
					return;
				$block.slideToggle(speed);
				$set.toggleClass(selectedClass);
				return false;
			});
		});
	}

	/* -----------------------------------------------------
	google map
	----------------------------------------------------- */
	ns.googlemap = function(dom_id, center_coord, markers) {

		var img_assets = '/assets/img/gmap/';

		var center = new google.maps.LatLng(center_coord[0], center_coord[1]);
		var zoom = 15;
		var mapTypeId = google.maps.MapTypeId.ROADMAP
		var map;
		var defmarker;
		var mapMarkers = [];
		var mapHtmls = [];
		var infoWindow = new google.maps.InfoWindow();


		$('.map_control_trigger').each(function(){
			var $dom = $(this).children();
			$dom.css('pointer-events', 'none');
			$(this).click(function() {
				$dom.css('pointer-events', 'auto');
			});
			$dom.mouseout(function() {
				$dom.css('pointer-events', 'none');
			});
		});

		function initialize(markers) {
			var myOptions = {
				zoom: zoom,
				center: center,
				mapTypeId: mapTypeId,
				scrollwheel: false
			}
			map = new google.maps.Map(document.getElementById(dom_id), myOptions);

			//マーカータイトル、座標、htmlタグ
			var markers = markers;

			for (var i = 0; i < markers.length; i++)
			{
				var marker = markers[i];

				var name = marker[0];
				var latlng = new google.maps.LatLng(marker[1], marker[2]);
				var category =  marker[3];
				var tag = marker[4];
				var address = marker[5];
				var html = '<div class="map_baloon">\n\
					<div class="map_baloon__inner">\n\
						<div class="map_baloon__contents">\n\
							<p class="map_baloon__name">'+name+'</p>\n\
							<p class="map_baloon__address">'+address+'</p>\n\
						</div>\n\
					</div>\n\
				</div>';
				var $html = $(html);
				if( tag ){
					$html.find('.map_baloon__inner').prepend( $('<div class="map_baloon__eyecatch">\n\
						<img src="'+img_assets+tag+'" alt="'+name+'" />\n\
					</div>') );
				}
				html = $html.prop('outerHTML');
				mapHtmls.push(html);
				if( i == 0 ){
					createMarker(latlng,html,map,category,name,tag,address,true);
				} else {
					createMarker(latlng,html,map,category,name,tag,address,false);
				}
			}

			//スタイル
			var stylez = [
				{
					"featureType": "landscape",
					"stylers": [
						{
							"lightness": 25
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road.highway",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "water",
					"stylers": [
						{
							"saturation": -40
						},
						{
							"lightness": 10
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#abdcef"
						}
					]
				}
			];

			var styledMapOptions = {
				map: map,
				name: "MY_STYLE"
			}

			var mapType =  new google.maps.StyledMapType(stylez,styledMapOptions);

			map.mapTypes.set('mystyle', mapType);
			map.setMapTypeId('mystyle');

		}
		initialize(markers);

		function createMarker(latlng,html,map,category,name,tag,url,init) {
			var iconOffset = new google.maps.Point(26, 43);
			var iconPosition = new google.maps.Point(0, 0);
			var iconSize = new google.maps.Size(26, 43);
			var iconReSize = new google.maps.Size(26, 43);
			var iconShadowSize = new google.maps.Size(57, 48);

			var defaultUrl = img_assets+"marker.png";
			var defaultShadowUrl = img_assets+"marker_shadow.png";
			var defaultIcon = new google.maps.MarkerImage(defaultUrl, iconSize, iconPosition, iconOffset, iconReSize);
			var defaultShadow = new google.maps.MarkerImage(defaultShadowUrl, iconShadowSize, iconPosition, iconOffset);

			var customIcons = {
				default: {icon:defaultIcon,shadow:defaultShadow}
			};

			var icon = customIcons[category] || {};

			var marker = new google.maps.Marker({
				map: map,
				position: latlng,
				icon: icon.icon,
				shadow: icon.shadow,
				title: name
			});
			mapMarkers.push(marker);

			google.maps.event.addListener(marker, 'click', function() {
				infoWindow.setContent(html);
				infoWindow.open(map,marker);
				if( $('.map_baloon__eyecatch').length > 0 ){
					$('.gm-style-iw').parent().addClass('gm-style-iw-parent');
				}
			});
			// 最初から吹き出し表示
			if( init ){
				infoWindow.setContent(html);
				infoWindow.open(map,marker);
				if( $('.map_baloon__eyecatch').length > 0 ){
					setTimeout(function(){
						$('.gm-style-iw').parent().addClass('gm-style-iw-parent');
					}, 100);
				}
			}

			// レスポンシブ対応
			google.maps.event.addDomListener(window, "resize", function() {
				var center = map.getCenter();
				google.maps.event.trigger(map, "resize");
				map.setCenter(center);
			});

		}

		function openInfoWindow(i){
			infoWindow.setContent(mapHtmls[i]);
			infoWindow.open(map,mapMarkers[i]);
		}

	}

})();


$(function(){
	$('body').imagesLoaded( { background: true }, function() {

		PUNITED.useful.replaceImage("data-spimg",639);
		PUNITED.common.header_scroll();
		PUNITED.common.page_main_description();
		PUNITED.common.check_main_header_y();
		PUNITED.common.footerToggle();

		PUNITED.useful.simpleToggle(".toggle",".title",".content");

		$('.widget_faq__wrapper.fn--toggle .widget_faq__question').append('<i class="toggle_handler"/>');
		PUNITED.useful.simpleToggle(".widget_faq__wrapper.fn--toggle .widget_faq",".widget_faq__question",".widget_faq__answer");

		PUNITED.common.drawerToggle(".drawer_nav .uk-offcanvas-bar .lv1 > li.fn--toggle"," > a"," > .lv2");
		var $obj = PUNITED.useful.checkBreakPont([640]);
		$obj.on("onPointChanged onPointInit", function(event, point){
			// ブレークポイントでトグル初期化
			$('.drawer_nav .uk-offcanvas-bar .lv1 > li.fn--toggle .lv2').attr({'style': ''});
			// 表示崩れが起きるので、ブレークポイントでドロワー初期化
			//UIkit.offcanvas.hide([force = true]);
		});
		// ページ固有のメインスライダー
		PUNITED.common.page_main_slider();
		// ページ固有のスクリプト
		PUNITED.common.page_style();
		// アンカー
		//PUNITED.common.anchor_link();
		var $obj2 = PUNITED.useful.checkBreakPont([640]);
		$obj2.on("onPointChanged onPointInit", function(event, point){
			//アンカーリンク
			var offset;
			switch( point ){
				case 1:
					offset = 40;
					break;
				default:
					offset = 74;
					break;
			}
			PUNITED.useful.innerPageLink("a", offset, true);
			PUNITED.useful.externalInnerPageLink("anc", offset);
		});

		$('.animate,.animate--fade-in-up').on('inview', function() {
			$(this).addClass('is_animated');
		});

		$('.drawer_btn').on('click',function(){
			$('body').toggleClass('is_menu_active');
		});

		$('.slick').slick({
			dots: false,
			infinite: true,
			speed: 1000,
			fade: true,
			arrows: false,
			cssEase: 'linear',
			autoplay: true,
			autoplaySpeed: 4000,
		});

		Splitting();
	});
});
