/* -------------------------------------------------------------------------

	fullImage v0.3
	
	require:jquery.js
				
	2014/06 Potential United inc.
	
	※リサイズするimgにwidthとheight必須
	
------------------------------------------------------------------------- */

;(function($) {
	
	$.fn.fullImage = function(options) {

		var defaults = {
			container: window,			// window or "parent" or jQuery selector or jQuery Object
			align: "center",			// "center" or "left" or "right"
			verticalAlign: "middle",	// "middle" or "top" or "bottom"
			excludeWidth:0,				//除外する幅
			excludeHeight:0,			//除外する高さ
			useDataAttr:false			//imgの属性width,heightのかわりにdata-width,data-heightを使うかどうか
		}
		
		options = $.extend(defaults, options);
		
		return this.each(function(){

			var $this = $(this);
			var $container;
			var imgW;
			var imgH;

			if(options.container == "parent") $container= $(this).parent();
			else $container= $(options.container);

			if(!options.useDataAttr){
				imgW = parseInt($this.attr("width"));
				imgH = parseInt($this.attr("height"));
			}else{
				imgW = parseInt($this.data("width"));
				imgH = parseInt($this.data("height"));
			}
			
			function resizeImage(){
				var rate = getFillFixScaleRate(imgW,imgH,$container.width() - options.excludeWidth, $container.height() - options.excludeHeight);
				$this.width(Math.ceil(imgW * rate));
				$this.height(Math.ceil(imgH * rate));
				$this.css({position:"absolute"});
				switch(options.align){
					case "center":
						$this.css({left:Math.floor(getCenteredX(imgW * rate, $container.width() - options.excludeWidth))+"px"}); break;
					case "left":
						$this.css({left:"0"}); break;
					case "right":
						$this.css({right:"0"}); break;
				}
				switch(options.verticalAlign){
					case "middle":
						$this.css({top:Math.floor(getCenteredY(imgH * rate, $container.height() - options.excludeHeight))+"px"}); break;
					case "top":
						$this.css({top:"0"}); break;
					case "bottom":
						$this.css({bottom:"0"}); break;
				}
			}
			$(window).resize(function(){
				resizeImage();
			});
			resizeImage();
			
		});
		
		//指定したサイズ内に比率を変えずに隙間なくフィットさせるスケールを求める
		function getFillFixScaleRate(imgW, imgH, targetW, targetH){
			var scaleW = targetW / imgW;
			var scaleH = targetH / imgH;
			return Math.max(scaleW, scaleH);
		}
		
		//指定したサイズ内に比率を変えずに収まるスケールを求める
		function getFixScaleRate(imgW, imgH, targetW, targetH){
			var rate;
			if (imgW / targetW > imgH / targetH){
				rate = targetW / imgW ;
			}else{
				rate = targetH / imgH;
			}
			return rate;
		}
		
		//縦中央を合わせるときのY,X
		function getCenteredY(imgH,targetH){
			return (targetH / 2 - imgH / 2);
		}
		function getCenteredX(imgW,targetW){
			return (targetW / 2 - imgW / 2);
		}
	}
	
})(jQuery);